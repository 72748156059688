import React, { Component, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import MdcAppLoader from './MdcAppLoader'

const PlaylistsEmbedStandAlone = React.lazy(
  () => import('./PlaylistsEmbedStandAlone' /* webpackChunkName: "PlaylistsEmbedStandAlone" */)
)

class PlaylistsEmbedStandAloneLoader extends Component {
  componentDidMount() {
    if (document.getElementById('mdcListsEmbed')) {
      const container = document.getElementById('mdcListsEmbed')
      const root = createRoot(container)
      const listID = container.getAttribute('data-listid')
      root.render(
        <Suspense fallback={<MdcAppLoader />}>
          <PlaylistsEmbedStandAlone listID={listID} />
        </Suspense>
      )
    }
  }

  render() {
    // do not render anything, rely on ComponentDidMount for render
    return <></>
  }
}

export default PlaylistsEmbedStandAloneLoader
