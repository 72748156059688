import React, { Suspense, useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import { useParams } from 'react-router-dom'
import MdcAppLoader from './MdcAppLoader'

const PeopleTab = React.lazy(() => import('./PeopleTab' /* webpackChunkName: "PeopleTab" */))

function PeopleTabLoader({ userSettings, containerId = 'peopletab-root' }) {
  const { personPostSlug, personType } = useParams()
  const rootRef = useRef()
  useEffect(() => {
    const container = document.getElementById(containerId)
    // only render on ComponentDidMount when the route matches, otherwise react will render no matter what
    if (
      userSettings.authenticated &&
      userSettings.proSearchPermission === 'proSearch' &&
      container
    ) {
      if (!rootRef.current) {
        rootRef.current = createRoot(container)
      }
      const root = rootRef.current
      root.render(
        <Suspense fallback={<MdcAppLoader />}>
          <PeopleTab
            userSettings={userSettings}
            personPostSlug={personPostSlug}
            personType={personType}
          />
        </Suspense>
      )
    }
  }, [containerId, personPostSlug, personType, userSettings])
  return <></>
}

export default PeopleTabLoader
