import clsx from 'clsx'
import { useCallback, useEffect, useRef, useState } from 'react'
import useSiteSearch from './hooks/useSiteSearch'
import SearchHints from './SearchHints'
import SearchResultList from './SearchResultList'
import styles from './SiteSearch.module.css'

export default function SiteSearch({ className, onFocus, onBlur }) {
  const searchPanelRef = useRef(null)
  const inputRef = useRef(null)
  const {
    searchText,
    results,
    loading,
    onSearchInputChange,
    active,
    onFocus: handleFocusInternal,
    onBlur: handleBlurInternal,
    onClear,
  } = useSiteSearch()
  const [activeResultItem, setActiveResultItem] = useState()

  const handleFocus = useCallback(
    (event) => {
      handleFocusInternal(event)
      onFocus(event)
    },
    [onFocus, handleFocusInternal]
  )

  const handleBlur = useCallback(
    (event) => {
      handleBlurInternal(event)
      onBlur(event)
    },
    [onBlur, handleBlurInternal]
  )

  const handleSearchInputChange = useCallback(
    (event) => {
      onSearchInputChange(event)
    },
    [onSearchInputChange]
  )

  const handleClear = useCallback(
    (event) => {
      handleBlur(event)
      onClear(event)
      inputRef.current.blur()
    },
    [handleBlur, onClear, inputRef]
  )

  const navigateResult = useCallback(
    (direction) => {
      const searchResultListItems =
        searchPanelRef.current?.querySelectorAll('.searchResultListItem')
      const activeClass = styles.activeResult
      let nextElement
      for (let index = 0; index < searchResultListItems.length; index++) {
        const element = searchResultListItems[index]
        if (element.classList.contains(activeClass)) {
          switch (direction) {
            case 'up':
              if (index === 0) {
                // handle the loop around case where on the first result and going up
                nextElement = searchResultListItems[searchResultListItems.length - 1]
              } else {
                nextElement = searchResultListItems[index - 1]
              }
              break
            case 'down':
              if (index === searchResultListItems.length - 1) {
                // handle the loop around case where on the last result and going down
                nextElement = searchResultListItems[searchResultListItems.length - 1]
              } else {
                nextElement = searchResultListItems[index + 1]
              }
              break
          }
          element.classList.remove(activeClass)
          nextElement?.classList.add(activeClass)
          nextElement?.scrollIntoView({ block: 'center' })
          setActiveResultItem(nextElement)
          return
        }
      }
      // if we got here result was selected so select the first one if one exists
      nextElement = searchResultListItems[0]
      nextElement?.classList.add(activeClass)
      setActiveResultItem(nextElement)
    },
    [setActiveResultItem]
  )

  const keyDownCallback = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault()
          navigateResult('down')
          break
        case 'ArrowUp':
          event.preventDefault()
          navigateResult('up')
          break
        case 'Escape':
          handleClear(event)
          break
        case 'Enter':
          const activeResult =
            activeResultItem ?? searchPanelRef.current?.querySelectorAll('.searchResultListItem')[0]
          activeResult?.click()
          break
      }
    },
    [navigateResult, handleClear, activeResultItem, searchPanelRef]
  )

  useEffect(() => {
    const clickCallback = (event) => {
      if (!searchPanelRef.current.contains(event.target)) {
        handleBlur(event)
      }
    }
    searchPanelRef.current?.addEventListener('keydown', keyDownCallback)
    document.addEventListener('click', clickCallback)
    return () => {
      document.removeEventListener('click', clickCallback)
      searchPanelRef.current?.removeEventListener('keydown', keyDownCallback)
    }
  }, [handleBlur, keyDownCallback, searchPanelRef])

  return (
    <div
      className={clsx({ [styles.searchActive]: active }, styles.search, className)}
      ref={searchPanelRef}
    >
      <div className={styles.searchInputContainer}>
        <input
          type="text"
          name="keywords"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="Search"
          tabIndex={1}
          value={searchText}
          onChange={handleSearchInputChange}
          onFocus={handleFocus}
          ref={inputRef}
        />
        <svg className={styles.searchIcon} aria-hidden="true" viewBox="0 0 18 18">
          <path d="m18 16.5-5.14-5.18h-.35a7 7 0 1 0-1.19 1.19v.35L16.5 18l1.5-1.5ZM12 7A5 5 0 1 1 2 7a5 5 0 0 1 10 0Z"></path>
        </svg>

        <svg
          className={clsx(styles.closeIcon, { [styles.hide]: !active || loading })}
          onClick={handleClear}
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M22.5 7.5L7.5 22.5"
            stroke="#1A1415"
            strokeWidth="1.95"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 7.5L22.5 22.5"
            stroke="#1A1415"
            strokeWidth="1.95"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          className={clsx(styles.loadingIcon, { [styles.hide]: !loading })}
          aria-hidden="true"
          viewBox="0 0 100 100"
        >
          <path
            fill="#1A1415"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
      <div className={clsx(styles.searchPopover, { [styles.hide]: !active })}>
        {searchText && !loading ? (
          <SearchResultList {...results} searchText={searchText} onClear={handleClear} />
        ) : (
          <SearchHints onClear={handleClear} />
        )}
      </div>
    </div>
  )
}
