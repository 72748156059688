export const checkForEmailConfirmation = () => ({
  Notifications: {
    checkForEmailConfirmation: true,
  },
})

export const setNotificationAsSeen = (notificationID) => ({
  Notifications: {
    setNotificationAsSeen: true,
    notificationID: notificationID,
  },
})

export const setNotificationsAsSeen = (ids) => ({
  Notifications: {
    setNotificationsAsSeen: true,
    notificationIDs: ids,
  },
})

export const removeNotification = (notificationID) => ({
  Notifications: {
    removeNotification: true,
    notificationID: notificationID,
  },
})

export const getAllAccountNotifications = () => ({
  Notifications: {
    getAccountNotifications: true,
  },
})

export const getActiveAccountNotifications = () => ({
  Notifications: {
    checkForProfileNotifications: true,
  },
})
