import clsx from 'clsx'
import styles from './NavMenuToggleButton.module.css'
export default function NavMenuToggleButton({ open, onToggle }) {
  return (
    <div className={styles.menuToggle}>
      <button
        className={clsx(styles.menuToggleOpen, { [styles.hide]: open })}
        onClick={() => onToggle(true)}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 6H22" stroke="#111111" strokeWidth="1.5"></path>
          <path d="M2 12H22" stroke="#111111" strokeWidth="1.5"></path>
          <path d="M2 18H22" stroke="#111111" strokeWidth="1.5"></path>
        </svg>
      </button>

      <button
        className={clsx(styles.menuToggleClosed, {
          [styles.hide]: !open,
        })}
        onClick={() => onToggle(false)}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 5.00024L19.1421 19.1424" stroke="#111111" strokeWidth="1.5"></path>
          <path d="M5 19.1424L19.1421 5.00024" stroke="#111111" strokeWidth="1.5"></path>
        </svg>
      </button>
    </div>
  )
}
