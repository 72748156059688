import { useCallback, useReducer } from 'react'
import { API_SERVER_NAME } from '../../../mdc_modules/mdcApiFetch'

const toSearchRequestUrl = (searchRequest) =>
  `${API_SERVER_NAME}/prosearch/sitesearch19-json.html?mdcsearch=${encodeURIComponent(
    searchRequest
  )}`

const reducer = (state, action) => {
  switch (action.type) {
    case 'search_request':
      const { searchText } = action
      return {
        ...state,
        searchText,
        error: null,
        loading: true,
      }
    case 'search_error':
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case 'search_success':
      const { data } = action
      const results = {
        resultCount: data.resultCount,
        personTypes: data.types,
        people: data.people,
        clients: data.clients,
        agencies: data.agencies,
        links: data.links,
      }
      return {
        ...state,
        results,
        error: null,
        loading: false,
      }
    case 'search_clear':
      return {
        ...initialState,
      }
    case 'search_focus':
      return {
        ...state,
        active: true,
      }
    case 'search_blur':
      return {
        ...state,
        active: false,
      }
  }
}

const initialState = {
  results: {},
  searchText: '',
  loading: false,
  active: false,
  error: null,
}

const debounce = (callback, wait) => {
  let timeoutId = null
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args)
    }, wait)
  }
}

export default function useSiteSearch() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleSearch = useCallback(
    debounce((searchText) => {
      const searchRequestUrl = toSearchRequestUrl(searchText)
      fetch(searchRequestUrl, {
        credentials: 'same-origin',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          dispatch({ type: 'search_error', error })
          return
        })
        .then((data) => {
          dispatch({ type: 'search_success', data })
        })
        .catch((error) => dispatch({ type: 'search_error', error }))
    }, 300),
    []
  )

  const onSearchInputChange = useCallback(
    (event) => {
      let searchText = event.target.value
      dispatch({ type: 'search_request', searchText })
      searchText = searchText.trim()
      if (!searchText) {
        return
      }
      handleSearch(searchText)
    },
    [handleSearch]
  )

  const onFocus = useCallback(() => {
    dispatch({ type: 'search_focus' })
  }, [])

  const onBlur = useCallback(() => {
    dispatch({ type: 'search_blur' })
  }, [])

  const onClear = useCallback(() => {
    dispatch({ type: 'search_clear' })
  }, [])

  return {
    ...state,
    onSearchInputChange,
    onFocus,
    onBlur,
    onClear,
  }
}
