import { Link } from 'react-router-dom'
import { checkProSearchPath, currentPath, re } from '../../helpers/checkProSearchPath'
import styles from './SearchHints.module.css'

export default function SearchHints({ onClear }) {
  return (
    <div className={styles.overlayContent}>
      <div className={styles.searchTips}>
        Tip: You can search by name or role (photographer, model, etc.)
      </div>
      <div className={styles.overlaySection}>
        <div className={styles.searchOverlayHeader}>
          <div className={styles.title}>Models.com ProSearch</div>
          {checkProSearchPath(re, currentPath) ? (
            <Link to={`/search/all`} onClick={onClear}>
              See all roles
            </Link>
          ) : (
            <a href="/search/all">See all roles</a>
          )}
        </div>
        <div className={styles.sectionLinks}>
          {checkProSearchPath(re, currentPath) ? (
            <Link to={`/search/model`} onClick={onClear}>
              Models
            </Link>
          ) : (
            <a href="/search/model">Models</a>
          )}
          {checkProSearchPath(re, currentPath) ? (
            <Link to={`/search/photographer`} onClick={onClear}>
              Photographers
            </Link>
          ) : (
            <a href="/search/photographer">Photographers</a>
          )}
          {checkProSearchPath(re, currentPath) ? (
            <Link to={`/search/fashion-editor-stylist`} onClick={onClear}>
              Fashion Editors/Stylists
            </Link>
          ) : (
            <a href="/search/fashion-editor-stylist">Fashion Editors/Stylists</a>
          )}
          {checkProSearchPath(re, currentPath) ? (
            <Link to={`/search/makeup-artist`} onClick={onClear}>
              Makeup Artists
            </Link>
          ) : (
            <a href="/search/makeup-artist">Makeup Artists</a>
          )}
        </div>
      </div>
      <div className={styles.overlaySection}>
        <div className={styles.title}>Agencies and companies</div>
        <div className={styles.overlayLinkList}>
          <a href="/agencies/">Modeling Agencies</a>
          <a href="/agencies/PhotoRep">Artist Agencies</a>
          <a href="/agencies/Production">Production &amp; Creative Services</a>
        </div>
      </div>
    </div>
  )
}
