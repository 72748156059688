import Cookies from 'js-cookie'
import React from 'react'
import { API_URL } from '../mdc_modules/mdcApiFetch'
import { VITE_PROSEARCH_VERSION } from '../mdc_modules/mdcConsts'

export const AuthContext = React.createContext({
  loading: true,
  authenticated: false,
  userSettings: {},
  error: undefined,
  reload: async () => undefined,
})

function AuthContextProvider({ children }) {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [authenticated, setAuthenticated] = React.useState(false)
  const [userSettings, setUserSettings] = React.useState({})

  const shouldLoadSettings = () => {
    const mdcCookie = Cookies.get('MDC_LF')
    return mdcCookie || window.location.hostname === 'localhost'
  }

  const loadSettings = async () => {
    const getUserSettingsPayload = {
      Settings: { getUserSettings: true, react_app_version: VITE_PROSEARCH_VERSION },
    }
    let response
    try {
      response = await fetch(API_URL + '?getUserSettings=1', {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(getUserSettingsPayload),
      })
    } catch (err) {
      console.error('There was a network error while loading user settings.', err)
      return
    }

    if (response.status !== 200) {
      console.error('There was error loading user settings. Status Code: ' + response.status)
      return
    }

    let data
    try {
      data = await response.json()
    } catch (err) {
      console.error('There was an error parsing the user settings result.', err)
      return
    }
    if (data.error) {
      setError(data.error)
      return
    }
    if (data.UserSettings) {
      setUserSettings(data.UserSettings)
      setAuthenticated(data.UserSettings.authenticated)
    }
  }

  React.useEffect(() => {
    const loadSettingsWithLoadingIndicator = async () => {
      setLoading(true)
      await loadSettings()
      setLoading(false)
    }
    if (shouldLoadSettings()) {
      loadSettingsWithLoadingIndicator()
    } else {
      setLoading(false)
    }
  }, [])

  const contextValue = {
    loading,
    authenticated,
    userSettings,
    error,
    reload: () => window.location.reload(),
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
