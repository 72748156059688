import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { checkProSearchPath } from '../../../helpers/checkProSearchPath'

const updatesPath = /\/(db|mdx|oftheminute|newfaces|news|interviews|trending)\/?.*/
const updatesPathMobile = /\/db\/?.*/
const showpackagesPath = /\/fashionweek\/showpackages.*/
const rankingsPath = /\/rankings\/?.*/
const agenciesPath = /\/(agencies|agency)\/?.*/
const newsPath = /\/news\/?.*/
const interviewsPath = /\/interviews\/?.*/
const trendingPath = /\/db\/trending\/?.*/
const newfacesPath = /\/newfaces\/?.*/
const peoplePath = /\/(search|models\/([^0-9]+)|people)\/?.*/
const featuredlistsPath = /\/lists\/featured/
const listsPath = /\/lists\/?.*/

export default function useActiveNavLink() {
  const { pathname } = useLocation()
  const [activeLink, setActiveLink] = useState('')

  useEffect(() => {
    if (
      checkProSearchPath(updatesPath, pathname) ||
      checkProSearchPath(updatesPathMobile, pathname) ||
      checkProSearchPath(showpackagesPath, pathname) ||
      checkProSearchPath(newsPath, pathname) ||
      checkProSearchPath(interviewsPath, pathname) ||
      checkProSearchPath(newfacesPath, pathname)
    ) {
      setActiveLink('updates')
    }
    if (checkProSearchPath(rankingsPath, pathname)) {
      setActiveLink('rankings')
    }
    if (checkProSearchPath(agenciesPath, pathname)) {
      setActiveLink('agencies')
    }
    if (checkProSearchPath(peoplePath, pathname)) {
      setActiveLink('people')
    }
    if (
      checkProSearchPath(listsPath, pathname) ||
      checkProSearchPath(featuredlistsPath, pathname)
    ) {
      setActiveLink('lists')
    }
  }, [
    pathname,
    updatesPath,
    updatesPathMobile,
    showpackagesPath,
    rankingsPath,
    agenciesPath,
    newsPath,
    interviewsPath,
    trendingPath,
    newfacesPath,
    peoplePath,
    listsPath,
    featuredlistsPath,
  ])
  return { activeLink }
}
