export const PLAYLIST_TITLE_MIN = 3
export const PLAYLIST_TITLE_MAX = 100
export const PLAYLIST_DEFAULT_MAX_LISTS = 3
export const PLAYLIST_PRO_MAX_LISTS = 1000
export const PLAYLIST_DEFAULT_MAX_PEOPLE = 20
export const PLAYLIST_PRO_MAX_PEOPLE = 200
export const PLAYLIST_DEFAULT_MAX_SHARES = 3
export const PLAYLIST_PRO_MAX_SHARES = 25
export const VITE_PROSEARCH_VERSION = "v4.2.2"; // 'not set'
export const BIO_TEXT_MAX_LENGTH = 3048

export const PERSON_TYPES = [
  //these were not in the final used person type list  // {
  //   "name": "Casting & Production",
  //   "personTypeNamePlural": "Casting & Production",
  //   "postSlug": "castingAndProduction",
  // },
  // {
  //   "name": "Creative Direction & Styling",
  //   "personTypeNamePlural": "Creative Direction & Styling",
  //   "postSlug": "creativeDirectionAndStyling",
  // },
  // {
  //   "name": "Beauty",
  //   "personTypeNamePlural": "Beauty",
  //   "postSlug": "beauty",
  // },
  {
    name: 'Model',
    personTypeNamePlural: 'Models',
    postSlug: 'model',
  },
  {
    name: 'Photography & Film',
    personTypeNamePlural: 'Photography & Film',
    postSlug: 'photographyAndFilm',
  },
  {
    name: 'Photographer',
    personTypeNamePlural: 'Photographers',
    postSlug: 'photographer',
  },
  {
    name: 'Fashion Editor/Stylist',
    personTypeNamePlural: 'Fashion Editors/Stylists',
    postSlug: 'fashion-editor-stylist',
  },
  {
    name: 'Makeup Artist',
    personTypeNamePlural: 'Makeup Artists',
    postSlug: 'makeup-artist',
  },
]

// Regular Expression Pattern (Ref: https://bit.ly/33cv2vn)
// eslint-disable-next-line
export const EMAIL_REGEX_PATTERN =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]$)/gim
export const PHONE_REGEX_PATTERN = /^\+?[0-9.() -]{7,32}$/
