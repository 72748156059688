import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { NotificationContext } from '../../providers/NotificationContextProvider'
import styles from './AlertBanner.module.css'

export default function AlertBanner() {
  const { alerts, announcements, acknowledgeNotification } = useContext(NotificationContext)
  const [notification, setNotification] = useState({})
  useEffect(() => {
    if (alerts && alerts.length > 0) {
      setNotification({ notification: alerts[0], type: 'alert' })
    } else if (announcements && announcements.length > 0) {
      setNotification({ notification: announcements[0], type: 'announcement' })
    } else {
      setNotification({ notification: null, type: '' })
    }
  }, [alerts, announcements])

  const handleClick = (event) => {
    acknowledgeNotification(notification.notification.notificationID)
  }

  return (
    <div className={clsx(styles.alertBanner, { [styles.hide]: !notification?.notification })}>
      <div className={styles.message}>
        <>{notification?.notification?.message}</>{' '}
        <a href={notification.notification?.link}>{notification.notification?.callToAction}</a>
      </div>
      <svg onClick={handleClick} className={styles.closeIcon} viewBox="0 0 30 30" fill="none">
        <path
          d="M22.5 7.5L7.5 22.5"
          stroke="#1A1415"
          strokeWidth="1.95"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 7.5L22.5 22.5"
          stroke="#1A1415"
          strokeWidth="1.95"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
