import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
// CategoryNav only renders on category pages or homepage
export default function CategoryNav({ className }) {
  const [activeCategory, setActiveCategory] = useState()
  const { pathname } = useLocation()
  useEffect(() => {
    const pathSegments = pathname.split('/')
    setActiveCategory(pathSegments.length > 1 ? pathSegments[1] : null)
  }, [pathname])

  const checkPath = new RegExp('^/$|^/(models|creatives|fashion|beauty|activism)/?([0-9]+)?$')
  const shouldRender = checkPath.test(pathname)
  if (!shouldRender) return <></>
  return (
    <ul className={clsx('sb-menu sb-menu--small', className)}>
      <li
        className={`sb-menu__item ${activeCategory === 'models' ? 'sb-menu__item--selected' : ''}`}
      >
        <a className="sb-link" href="/models">
          Models.
        </a>
      </li>
      <li
        className={`sb-menu__item ${
          activeCategory === 'creatives' ? 'sb-menu__item--selected' : ''
        }`}
      >
        <a className="sb-link" href="/creatives">
          Creatives.
        </a>
      </li>
      <li
        className={`sb-menu__item ${activeCategory === 'fashion' ? 'sb-menu__item--selected' : ''}`}
      >
        <a className="sb-link" href="/fashion">
          Fashion.
        </a>
      </li>
      <li
        className={`sb-menu__item ${activeCategory === 'beauty' ? 'sb-menu__item--selected' : ''}`}
      >
        <a className="sb-link" href="/beauty">
          Beauty.
        </a>
      </li>
    </ul>
  )
}
