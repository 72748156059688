export default function Header({ title, onBack, onClose }) {
  return (
    <div className="sb-pop-up-wrapper__heading-layout">
      <div className="sb-pop-up-wrapper__icon" onClick={() => onBack?.()}>
        {onBack && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 5L9.16667 12L15 19"
              stroke="#111111"
              strokeWidth="1.5"
              strokeLinecap="square"
            ></path>
          </svg>
        )}
      </div>
      <div className="sb-pop-up-wrapper__heading">{title}</div>
      <button
        className="sb-pop-up-wrapper__icon"
        style={{ background: 'none', border: 'none' }}
        onClick={() => onClose()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M5 5.00024L19.1421 19.1424" stroke="#111111" strokeWidth="1.5"></path>
          <path d="M5 19.1424L19.1421 5.00024" stroke="#111111" strokeWidth="1.5"></path>
        </svg>
      </button>
    </div>
  )
}
