import clsx from 'clsx'
import { useState } from 'react'
import { HOSTNAME } from '../../mdc_modules/mdcApiFetch'
import InlineLoader from '../InlineLoader'

export default function ForgotPassword({ onSuccess }) {
  const [formData, setFormData] = useState({ email: '' })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.trim(),
    }))
    setErrors((prev) => ({ ...prev, [event.target.name]: null, submissionError: null }))
  }

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    try {
      const response = await fetch(`${HOSTNAME}/auth/password-recovery`, {
        method: 'POST',
        body: JSON.stringify({
          email: formData['email'],
        }),
      })
      if (response.ok) {
        const { success, message } = await response.json()
        if (success) {
          onSuccess(message)
        } else {
          setErrors({ submissionError: message })
        }
      } else {
        setErrors({
          submissionError: 'Error submitting request. Please try again or contact support.',
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="sb-pop-up-wrapper__content-wrapper">
      <div className="db_forgot_password_pop_up__wrapper">
        <p className="db_forgot_password_pop_up__hint">
          Please enter your email address to receive instructions for resetting your password.
        </p>
        <label className="sb-text-input__layout">
          <div
            className={clsx('sb-text-input__wrapper', {
              'sb-text-input__wrapper--error': errors.email,
            })}
          >
            <span className={clsx('sb-text-input__label', { '': errors.email })}>Email</span>
            <input
              className={clsx('sb-text-input__input', { 'sb-text-input__input': errors.email })}
              name="email"
              placeholder="Email"
              type="email"
              maxLength={50}
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          {errors.email && (
            <p className="sb-text-input__hint sb-text-input__hint--error">{errors.email}</p>
          )}
        </label>

        <button type="submit" className="sb-button sb-button--primary">
          {loading ? <InlineLoader /> : 'Send Reset Link'}
        </button>
        <div
          style={{
            color: '#BB2A33',
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: '15.6px',
            transition: 'height .5s',
            height: errors.submissionError ? 'auto' : 0,
          }}
        >
          {errors.submissionError}
        </div>
      </div>
    </form>
  )
}
