import { useEffect, useRef } from 'react'
import styles from './Overlay.module.css'
export default function Overlay({ children, onClick }) {
  const popover = useRef(null)

  function onClickHandler(event) {
    // check from when the overlay is clicked directly
    if (popover.current && popover.current === event.composedPath()[0]) {
      onClick?.()
    }
  }

  useEffect(() => {
    document.body.style.position = 'fixed'
    document.body.style.top = `-${Math.round(window.scrollY)}px`
    document.body.style.right = '0px'
    document.body.style.left = '0px'
    document.body.addEventListener('click', onClickHandler)
    return () => {
      document.body.removeEventListener('click', onClickHandler)
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.right = ''
      document.body.style.left = ''
    }
  }, [])
  return (
    <div ref={popover} className={styles.overlay}>
      {children}
    </div>
  )
}
