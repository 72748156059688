import clsx from 'clsx'
import { useState } from 'react'
export default function TextInput({
  name,
  label,
  type = 'text',
  maxLength = 50,
  value,
  onChange,
  error,
  leftIcon,
  ...props
}) {
  const [currentInputType, setCurrentInputType] = useState(type)
  const toggleInputType = () => {
    setCurrentInputType((prev) => (prev === 'password' ? 'text' : 'password'))
  }
  return (
    <label className="sb-text-input__layout">
      <div
        className={clsx('sb-text-input__wrapper', {
          'sb-text-input__wrapper--error': error,
        })}
      >
        <span className={clsx('sb-text-input__label', { '': error })}>{label}</span>
        <input
          className={clsx('sb-text-input__input', { 'sb-text-input__input': error })}
          name={name}
          placeholder={label}
          type={currentInputType}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          {...props}
        />
        {leftIcon && (
          <div className="sb-text-input__icon" onClick={toggleInputType}>
            {leftIcon}
          </div>
        )}
      </div>
      {error && <p className="sb-text-input__hint sb-text-input__hint--error">{error}</p>}
    </label>
  )
}
