import clsx from 'clsx'
import styles from './AvatarButton.module.css'
import sharedStyles from './shared.module.css'

export default function AvatarButton({
  children,
  open,
  hasNotifications,
  avatarImageSrc,
  initials,
  onToggle,
}) {
  const handleToggle = () => {
    onToggle()
  }
  return (
    <div>
      <button
        className={clsx(styles.avatar, {
          [styles.avatarOpen]: open,
          [styles.notificationBadge]: hasNotifications,
        })}
        onClick={handleToggle}
      >
        {avatarImageSrc ? (
          <div className={styles.avatarImageWrapper}>
            <div className={styles.avatarImage}>
              <img src={avatarImageSrc} />
            </div>
          </div>
        ) : initials ? (
          <span className={styles.avatarInitials}>{initials}</span>
        ) : (
          <></>
        )}

        <span className={clsx(styles.avatarIcon, sharedStyles.chevronBlackIcon)}></span>
      </button>
      {children}
    </div>
  )
}
