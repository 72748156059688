import { useEffect, useState } from 'react'
import TextInput from './TextInput'
import InlineLoader from '../InlineLoader'
import { HOSTNAME } from '../../mdc_modules/mdcApiFetch'
import useAuthSocialMeta from './use-auth-social-meta'

const currentDate = new Date()
const thirteenYearsAgo = new Date(
  currentDate.getFullYear() - 13,
  currentDate.getMonth(),
  currentDate.getDate()
)
const eighteenYearsAgo = new Date(
  currentDate.getFullYear() - 18,
  currentDate.getMonth(),
  currentDate.getDate()
)
const minDob = thirteenYearsAgo.toISOString().split('T')[0]
const requiresConsent = (dob) => dob > eighteenYearsAgo
const isUnderage = (dob) => dob > thirteenYearsAgo

export default function Register({ social, source, onSuccess }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    dob: '',
    firstName: '',
    lastName: '',
    subscribeToNewsletter: false,
    parentConsent: false,
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [consentRequired, setConsentRequired] = useState(false)
  const {data: socialMetadata, loading: socialMetadataLoading} = useAuthSocialMeta(social)

  useEffect(() => {
    if (socialMetadataLoading || !socialMetadata) return
    const { email, dob, firstName, lastName} = socialMetadata
    setFormData(prev => ({...prev, email, dob, firstName, lastName}))
  }, [socialMetadata])

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.trim(),
    }))
    if (event.target.name === 'dob') {
      const dob = new Date(event.target.value)
      if (isUnderage(dob)) {
        setErrors((prev) => ({
          ...prev,
          [event.target.name]:
            'Sorry, you must be 13 years or older to sign up for Models.com membership',
        }))
        return
      }
      setConsentRequired(requiresConsent(dob))
    }
    setErrors((prev) => ({ ...prev, [event.target.name]: null, submissionError: null }))
  }

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()

    try {
      const form = event.target
      const currentFormData = new FormData(form)

      const termsAccepted = currentFormData.get('terms') === 'on'
      const parentConsent = currentFormData.get('parentConsent') === 'on'
      const subscribeToNewsletterAccepted = currentFormData.get('subscribeToNewsletter') === 'on'

      let hasErrors = false
      setErrors({})
      if (!formData.email) {
        setErrors((prev) => ({ ...prev, email: 'Email is required' }))
        hasErrors = true
      }
      if (!social && !formData.password) {
        setErrors((prev) => ({ ...prev, password: 'Password is required' }))
        hasErrors = true
      }
      if (!formData.firstName) {
        setErrors((prev) => ({ ...prev, firstName: 'First name is required' }))
        hasErrors = true
      }
      if (!formData.lastName) {
        setErrors((prev) => ({ ...prev, lastName: 'Last name is required' }))
        hasErrors = true
      }
      if (!formData.dob) {
        setErrors((prev) => ({ ...prev, dob: 'Date of birth is required' }))
        hasErrors = true
      }
      if (!termsAccepted) {
        setErrors((prev) => ({ ...prev, submissionError: 'Must accept terms to register' }))
        hasErrors = true
      }
      if (!parentConsent && consentRequired) {
        setErrors((prev) => ({
          ...prev,
          submissionError: 'Must have parental consent to register',
        }))
        hasErrors = true
      }
      if (hasErrors) {
        return
      }

      const response = await fetch(`${HOSTNAME}/auth/register`, {
        method: 'POST',
        body: JSON.stringify({
          email: formData['email'],
          password: formData['password'],
          dob: formData['dob'],
          firstName: formData['firstName'],
          lastName: formData['lastName'],
          parentConsent: parentConsent,
          subscribeToNewsletter: subscribeToNewsletterAccepted,
          social,
          source,
        }),
      })
      if (response.ok) {
        const { success, message } = await response.json()
        if (success) {
          onSuccess(message)
        } else {
          setErrors({ submissionError: message })
        }
      } else {
        setErrors({
          submissionError: 'Error submitting request. Please try again or contact support.',
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <form className="sb-sign-up-pop-up__form-layout" onSubmit={handleSubmit}>
        <TextInput
          name="email"
          label="Email"
          type="email"
          maxLength={200}
          value={formData.email}
          error={errors.email}
          onChange={handleChange}
        />

        {social ? null : (
          <TextInput
            name="password"
            label="Password"
            type="password"
            maxLength={200}
            value={formData.password}
            error={errors.password}
            onChange={handleChange}
          />
        )}
        <TextInput
          name="firstName"
          label="First name"
          maxLength={80}
          value={formData.firstName}
          error={errors.firstName}
          onChange={handleChange}
        />

        <TextInput
          name="lastName"
          label="Last name"
          maxLength={80}
          value={formData.lastName}
          error={errors.lastName}
          onChange={handleChange}
        />

        {/* <TextInput
          name="passwordConfirmation"
          label="Confirm Password"
          type="passwordConfirmation"
          maxLength={50}
          value={formData.passwordConfirmation}
          error={errors.passwordConfirmation}
          onChange={handleChange}
        /> */}

        <TextInput
          name="dob"
          label="Birthday"
          type="date"
          max={minDob}
          value={formData.dob}
          error={errors.dob}
          onChange={handleChange}
        />

        {consentRequired && (
          <div className="sb-checkbox-input__wrapper">
            <label className="sb-checkbox-input__input-layout">
              <input
                className="sb-checkbox-input__input-hidden"
                name="parentConsent"
                type="checkbox"
                tabIndex="-1"
              />
              <span className="sb-checkbox-input__input  ">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M13 4.25L6.125 11.125L3 8"
                    stroke="white"
                    strokeWidth="1.6666"
                    strokeLinecap="square"
                  ></path>
                </svg>
              </span>
              <span className="sb-checkbox-input__label  ">
                If you are under 18 years old, you need to have your parent's consent to become a
                member. Check this box to confirm you have your parent's consent to become a
                Models.com member
              </span>
            </label>
          </div>
        )}

        <div className="sb-checkbox-input__wrapper">
          <label className="sb-checkbox-input__input-layout">
            <input
              className="sb-checkbox-input__input-hidden"
              name="terms"
              type="checkbox"
              tabIndex="-1"
            />
            <span className="sb-checkbox-input__input  ">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M13 4.25L6.125 11.125L3 8"
                  stroke="white"
                  strokeWidth="1.6666"
                  strokeLinecap="square"
                ></path>
              </svg>
            </span>
            <span className="sb-checkbox-input__label  ">
              I agree to the{' '}
              <a href="/register/tos.cfm" target="_blank" style={{ textDecoration: 'underline' }}>
                terms and conditions
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                href="/company/privacy.html"
                style={{ textDecoration: 'underline' }}
              >
                privacy policy
              </a>
            </span>
          </label>
        </div>
        <div className="sb-checkbox-input__wrapper">
          <label className="sb-checkbox-input__input-layout">
            <input
              className="sb-checkbox-input__input-hidden"
              name="subscribeToNewsletter"
              type="checkbox"
              tabIndex="-1"
              onChange={handleChange}
            />
            <span className="sb-checkbox-input__input  ">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M13 4.25L6.125 11.125L3 8"
                  stroke="white"
                  strokeWidth="1.6666"
                  strokeLinecap="square"
                ></path>
              </svg>
            </span>
            <span className="sb-checkbox-input__label  ">Subscribe to newsletter</span>
          </label>
        </div>

        <button type="submit" className="sb-button sb-button--primary">
          {loading ? <InlineLoader /> : 'Register'}
        </button>
        <div
          style={{
            color: '#BB2A33',
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: '15.6px',
            transition: 'height .5s',
            height: errors.submissionError ? 'auto' : 0,
          }}
        >
          {errors.submissionError}
        </div>
      </form>
    </>
  )
}
