import clsx from 'clsx'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../providers/AuthContextProvider'
import { NotificationContext } from '../../providers/NotificationContextProvider'
import AlertBanner from './AlertBanner'
import AvatarButton from './AvatarButton'
import CategoryNav from './CategoryNav'
import useActiveNavLink from './hooks/useActiveNavLink'
import useFocus from './hooks/useFocus'
import LoginButton from './LoginButton'
import NavMenuPopover from './NavMenuPopover'
import NavMenuToggleButton from './NavMenuToggleButton'
import NewsNavMenu from './NewsNavMenu'
import PeopleNavMenu from './PeopleNavMenu'
import SiteSearch from './SiteSearch'
import styles from './TopNav.module.css'
import UserMenuPopover, { toInitials } from './UserMenuPopover'
import Logo from '../Logo'
import Overlay from '../Overlay/Overlay'
import Login from '../Registration'

export default function TopNav() {
  const { loading, userSettings } = useContext(AuthContext)
  const { hasNotifications } = useContext(NotificationContext)
  const { activeLink } = useActiveNavLink()
  const [userMenuPopoverOpen, setUserMenuPopoverOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [siteSearchActive, setSiteSearchActive] = useState(false)
  const [topNavHeight, setTopNavHeight] = useState(0)
  const [showLoginModule, setShowLoginModule] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [activeAuthView, setActiveAuthView] = useState()

  useEffect(() => {
    function attachClickHandler(element, type) {
      element.addEventListener('click', (event) => {
        event.preventDefault()
        document.querySelectorAll('.close-reveal-modal').forEach(ele => ele.click())
        setActiveAuthView(type)
        setShowLoginModule(true)
      })
    }

    // Get all existing login launcher elements
    const existingLoginLauncherElements = document.querySelectorAll('.login-flow-launcher')
    existingLoginLauncherElements.forEach((element) => attachClickHandler(element, 'login'))

    // Get all existing register launcher elements
    const existingRegisterLauncherElements = document.querySelectorAll('.register-flow-launcher')
    existingRegisterLauncherElements.forEach((element) => attachClickHandler(element, 'register'))

    // Create a MutationObserver to watch for new elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            node.querySelectorAll?.('.login-flow-launcher').forEach(launcher => attachClickHandler(launcher, 'login'))
            node.querySelectorAll?.('.register-flow-launcher').forEach(launcher => attachClickHandler(launcher, 'register'))
          })
        }
      })
    })

    // Configure the observer to watch for child list mutations
    const config = { 
      childList: true,
      subtree: true
     }

    // Start observing the document body
    observer.observe(document.body, config)

    return () => {
      observer.disconnect()
    }
  }, [])

  const topNavRef = useCallback(
    (node) => {
      if (node) {
        setTopNavHeight(node.getBoundingClientRect().height)
        window.topNavOffset = node.getBoundingClientRect().height
      }
    },
    [hasNotifications, loading]
  )
  const handleUserNavToggle = () => {
    setUserMenuPopoverOpen((prev) => !prev)
    if (menuOpen) {
      setMenuOpen(false)
    }
  }
  const handleNavMenuToggle = (value) => {
    setMenuOpen(value)
    if (userMenuPopoverOpen) {
      setUserMenuPopoverOpen(false)
    }
  }
  const handleShowLoginModule = () => {
    setShowLoginModule(true)
  }
  const { ref, setOnBlur } = useFocus()
  useEffect(() => {
    setOnBlur(() => () => setUserMenuPopoverOpen(false))
  }, [setOnBlur, setUserMenuPopoverOpen])

  useEffect(() => {
    if (loading) return
    const isResetFlow = searchParams.has('reset')
    const isRegisterFlow = !userSettings?.authenticated && (location.pathname === '/register' || location.pathname === '/register/')
    const isSocialRegisterFlow = searchParams.has('source')
    const isLoginFlow = location.pathname.startsWith('/account/') && !userSettings?.authenticated

    if (isLoginFlow) {
      setActiveAuthView('login')
    } else if (isRegisterFlow) {
      setActiveAuthView(isSocialRegisterFlow ? 'register-social' : 'register')
    } else if (isResetFlow) {
      setActiveAuthView('resetPassword')
    }
    setShowLoginModule(isLoginFlow || isResetFlow || isRegisterFlow)
  }, [loading, userSettings, searchParams])
  return (
    <>
      <div className={styles.TopNav} ref={topNavRef}>
        <div className={styles.container}>
          <NavMenuToggleButton open={menuOpen} onToggle={handleNavMenuToggle} />
          <NavMenuPopover open={menuOpen} userSettings={userSettings} />
          <a href="/" className={styles.logo}>
            <Logo />
          </a>
          <nav className={clsx(styles.nav, styles.navPrimary)}>
            <div
              className={clsx(styles.link, {
                [styles.linkActive]: activeLink === 'rankings',
              })}
            >
              <a href="/rankings/">Rankings</a>
            </div>
            <div
              className={clsx(styles.link, {
                [styles.linkActive]: activeLink === 'agencies',
              })}
            >
              <a href="/agencies/">Agencies</a>
            </div>
            <div
              className={clsx(styles.link, {
                [styles.linkActive]: activeLink === 'people',
              })}
            >
              <PeopleNavMenu />
            </div>
          </nav>

          <SiteSearch
            className={styles.hideSmall}
            onBlur={() => setSiteSearchActive(false)}
            onFocus={() => setSiteSearchActive(true)}
          />

          <nav className={clsx(styles.nav)}>
            <div
              className={clsx(styles.link, {
                [styles.linkActive]: activeLink === 'updates',
              })}
            >
              <NewsNavMenu>
                <a href="/db/advertising" className={styles.iconLink}>
                  <i aria-hidden="true" className="fal fa-clone"></i>
                  News
                </a>
              </NewsNavMenu>
            </div>
            <div
              className={clsx(styles.link, {
                [styles.linkActive]: activeLink === 'lists',
              })}
            >
              <a href="/lists/" className={styles.iconLink}>
                <i aria-hidden="true" className="fal fa-heart"></i>
                Lists
              </a>
            </div>
          </nav>
          <div className={clsx(styles.userMenu, { [styles.ghost]: loading })} ref={ref}>
            {userSettings?.authenticated ? (
              <AvatarButton
                hasNotifications={hasNotifications}
                avatarImageSrc={userSettings?.userPicture}
                initials={toInitials(userSettings?.name)}
                open={userMenuPopoverOpen}
                onToggle={handleUserNavToggle}
              >
                <UserMenuPopover open={userMenuPopoverOpen} userSettings={userSettings} />
              </AvatarButton>
            ) : (
              <LoginButton
                open={userMenuPopoverOpen}
                onToggle={handleShowLoginModule}
              ></LoginButton>
            )}
          </div>
        </div>

        <SiteSearch
          className={styles.showSmall}
          onBlur={() => setSiteSearchActive(false)}
          onFocus={() => setSiteSearchActive(true)}
        />
        <div className={styles.subNavContent}>
          <div className="rainbow"></div>
          <CategoryNav className={styles.hideSmall} />
          <AlertBanner />
        </div>
      </div>
      <div style={{ height: `${topNavHeight}px`, visibility: 'hidden' }} />
      {showLoginModule && (
        <Overlay onClick={() => setShowLoginModule(false)}>
          <Login defaultActiveView={activeAuthView} onClose={() => setShowLoginModule(false)} />
        </Overlay>
      )}
    </>
  )
}
