import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import TextInput from './TextInput'
import { HOSTNAME } from '../../mdc_modules/mdcApiFetch'
import { AuthContext } from '../../providers/AuthContextProvider'
import InlineLoader from '../InlineLoader'
import EyeIcon from '../../icons/EyeIcon'

export default function ResetPassword({ onSuccess }) {
  const { reload } = useContext(AuthContext)
  let [searchParams, setSearchParams] = useSearchParams()
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({ email: null, password: null })
  const [loading, setLoading] = useState(false)

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.trim(),
    }))
    setErrors((prev) => ({ ...prev, [event.target.name]: null, submissionError: null }))
  }

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    try {
      let hasErrors = false
      setErrors({})
      if (!formData.email) {
        setErrors((prev) => ({ ...prev, email: 'Email is required' }))
        hasErrors = true
      }
      if (!formData.password) {
        setErrors((prev) => ({ ...prev, password: 'Password is required' }))
        hasErrors = true
      }
      if (hasErrors) {
        return
      }

      const response = await fetch(`${HOSTNAME}/auth/newpassword-recovery-verify`, {
        method: 'POST',
        body: JSON.stringify({
          action: 'newPasswordRecoveryVerify',
          email: formData['email'],
          newRecoveryPassword: formData['password'],
          token: searchParams.get('reset'),
        }),
      })
      if (response.ok) {
        const { success, message } = await response.json()
        if (success) {
          searchParams.delete('reset')
          setSearchParams(searchParams)
          await reload()
          onSuccess(message)
        } else {
          setErrors({ submissionError: message })
        }
      } else {
        setErrors({
          submissionError: 'Error submitting request. Please try again or contact support.',
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <form className="sb-sign-in-pop-up__form-layout" onSubmit={handleSubmit}>
        <TextInput
          name="email"
          label="Email"
          type="email"
          maxLength={50}
          value={formData.email}
          error={errors.email}
          onChange={handleChange}
        />

        <TextInput
          name="password"
          label="New Password"
          type="password"
          maxLength={50}
          value={formData.password}
          error={errors.password}
          leftIcon={<EyeIcon />}
          onChange={handleChange}
        />

        <button
          xmlns="http://www.w3.org/1999/xhtml"
          type="submit"
          className="sb-button sb-button--primary"
        >
          {loading ? <InlineLoader /> : 'Reset Password'}
        </button>

        <div
          style={{
            color: '#BB2A33',
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: '15.6px',
            transition: 'height .5s',
            height: errors.submissionError ? 'auto' : 0,
          }}
        >
          {errors.submissionError}
        </div>
      </form>
    </>
  )
}
