import { useState, useEffect } from 'react';
import { HOSTNAME } from '../../mdc_modules/mdcApiFetch'

function useAuthSocialMeta(shouldLoad) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!shouldLoad) return
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${HOSTNAME}/auth/social-meta`);
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, error, isLoading };
}

export default useAuthSocialMeta;