import Logo from '../Logo'
import { useContext, useState } from 'react'
import clsx from 'clsx'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import RequestConfirmation from './RequestConfirmation'
import Header from './Header'
import ResetPassword from './ResetPassword'
import Register from './Register'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AuthContext } from '../../providers/AuthContextProvider'

function Tabs({ activeView, onChange }) {
  return (
    <div className="sb-sign-up-pop-up__tabs-layout">
      <a
        className={clsx('sb-tab-item', { 'sb-tab-item--active': activeView === 'login' })}
        onClick={() => onChange('login')}
      >
        Login
      </a>
      <a
        className={clsx('sb-tab-item', {
          'sb-tab-item--active': activeView === 'register' || activeView === 'register-social',
        })}
        onClick={() => onChange('register')}
      >
        Create an Account
      </a>
    </div>
  )
}

function SocialLoginMessage() {
  return (
    <ul style={{ listStyle: 'none', fontSize: '.9rem', margin: 0, padding: 0 }}>
      <li style={{ padding: '.5rem 0', color: 'green' }}>
        <span style={{ width: '1rem', display: 'inline-flex', justifyContent: 'center' }}>
          &#10003;
        </span>{' '}
        Successfully logged in via Facebook
      </li>
      <li style={{ padding: '.5rem 0', color: 'black' }}>
        <span style={{ width: '1rem', display: 'inline-flex', justifyContent: 'center' }}>
          &#8250;
        </span>{' '}
        To complete your registration please submit the form below
      </li>
    </ul>
  )
}

export default function AuthenticationForm({ defaultActiveView = 'login', onClose }) {
  const { reload } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeView, setActiveView] = useState({ id: defaultActiveView })
  const [searchParams] = useSearchParams()
  const isSocialRegistration = location.pathname === '/register' && searchParams.has('source')

  const handleRegistrationConfirmation = async () => {
    void reload()
    onClose?.()
    // registration page contains no content so if user is on registration page navigate them to homepage
    if (location.pathname === '/register') {
      navigate('/', { replace: true })
    }
  }

  return (
    <div className="sb-pop-up-wrapper__layout" style={{ backgroundColor: 'white', width: '100%' }}>
      {(activeView.id === 'login' ||
        activeView.id === 'register' ||
        activeView.id === 'register-social') && (
        <Header
          title={
            <div style={{ width: '96px' }}>
              <Logo />
            </div>
          }
          onClose={onClose}
        />
      )}
      {activeView.id === 'forgotPassword' && (
        <Header
          title="Forgot Password"
          onClose={onClose}
          onBack={() => setActiveView({ id: 'login' })}
        />
      )}
      {activeView.id === 'resetPassword' && (
        <Header
          title="Reset your password"
          onClose={onClose}
          onBack={() => setActiveView({ id: 'forgotPassword' })}
        />
      )}
      <div className="sb-pop-up-wrapper__content-wrapper">
        {activeView.id === 'login' && (
          <>
            <Tabs activeView={activeView.id} onChange={(id) => setActiveView({ id })} />
            <Login
              onSuccess={onClose}
              onForgotPassword={() => setActiveView({ id: 'forgotPassword' })}
            />
          </>
        )}
        {activeView.id === 'register' && (
          <>
            <Tabs activeView={activeView.id} onChange={(id) => setActiveView({ id })} />
            <Register
              onSuccess={(message) => setActiveView({ id: 'registerRequestConfirmation', message })}
            />
          </>
        )}
        {activeView.id === 'register-social' && isSocialRegistration && (
          <>
            <Tabs activeView={activeView.id} onChange={(id) => setActiveView({ id })} />
            {isSocialRegistration ? <SocialLoginMessage /> : null}
            <Register
              social
              source={searchParams.get('source')}
              onSuccess={(message) => setActiveView({ id: 'registerRequestConfirmation', message })}
            />
          </>
        )}
        {activeView.id === 'forgotPassword' && (
          <ForgotPassword
            onSuccess={(message) => setActiveView({ id: 'resetRequestConfirmation', message })}
          />
        )}
        {activeView.id === 'resetRequestConfirmation' && (
          <RequestConfirmation message={activeView.message} onConfirm={onClose} />
        )}
        {activeView.id === 'registerRequestConfirmation' && (
          <RequestConfirmation
            message={activeView.message}
            onConfirm={handleRegistrationConfirmation}
          />
        )}
        {activeView.id === 'resetPassword' && (
          <ResetPassword
            onSuccess={(message) => setActiveView({ id: 'resetRequestConfirmation', message })}
          />
        )}
      </div>
    </div>
  )
}
