export function Tos() {
  return (
    <>
      <p className="sb-sign-up-pop-up__terms-and-conditions">
        By continuing, you agree to Models.com's <a href="/register/tos.cfm">Terms of service</a>{' '}
        and acknowledge you've read our <a href="/company/privacy.html">Privacy Policy</a>.
      </p>
      <div className="sb-sign-up-pop-up__terms-and-conditions">
        <div style={{ display: 'inline-flex', flexDirection: 'row', gap: '.5rem' }}>
          <a href="/help/">Help & FAQs</a>
          <a href="/feedback/feedback.html">Contact</a>
          <a href="/about/services/">About us</a>
        </div>
      </div>
    </>
  )
}
