import { useCallback, useEffect, useRef, useState } from 'react'

export default function useFocus() {
  const ref = useRef(null)
  const [onBlur, setOnBlur] = useState()

  const clearOnBlur = () => setOnBlur(null)

  const keyDownCallback = useCallback(
    (event) => {
      switch (event.key) {
        case 'Escape':
          onBlur?.()
          break
      }
    },
    [onBlur]
  )

  const clickCallback = useCallback(
    (event) => {
      event.stopPropagation()
      if (!ref.current.contains(event.target)) {
        onBlur?.()
      }
    },
    [ref.current, onBlur]
  )

  useEffect(() => {
    ref.current?.addEventListener('keydown', keyDownCallback)
    document.addEventListener('click', clickCallback)
    return () => {
      document.removeEventListener('click', clickCallback)
      ref.current?.removeEventListener('keydown', keyDownCallback)
    }
  }, [keyDownCallback, clickCallback])

  return { ref, setOnBlur, clearOnBlur }
}
