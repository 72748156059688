import React from 'react'
import { fetchData } from '../mdc_modules/mdcApiFetch'
import {
  getActiveAccountNotifications,
  removeNotification,
  setNotificationAsSeen,
} from '../mdc_modules/notificationsApiRequests'
import { AuthContext } from './AuthContextProvider'

export const NotificationContext = React.createContext({
  alerts: [],
  announcements: [],
  loading: false,
  hasNotifications: false,
  acknowledgeNotification: (notificationId) => {},
  removeNotification: (notificationId) => {},
})

// TODO move this to server side settings so we can change on the fly
const NOTIFICATION_POLLING_INTERVAL_MS = 2 * 60 * 1000 // 2 mins

const EVENT_REFRESH_NOTIFICATIONS = 'mdxrefreshnotifications'

// this is a workaround introduced to allow the AccountNotification page
// to refresh notifications when they are acknowledged/removed from that view
// this is needed because the NotificationContext is not share between
// different roots created via ReactDOM.render(...)
export const refreshNotifications = () => {
  document.dispatchEvent(new Event(EVENT_REFRESH_NOTIFICATIONS))
}

function NotificationContextProvider({ children }) {
  const { authenticated } = React.useContext(AuthContext)
  // load initial state of notifications from auth context
  const [alerts, setAlerts] = React.useState([])
  const [announcements, setAnnouncements] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [hasNotifications, setHasNotifications] = React.useState(false)

  const handleAcknowledgeNotification = async (notificationId) => {
    const response = await fetchData(setNotificationAsSeen(notificationId))
    if (response.success) {
      setAlerts(alerts.filter((alert) => alert.notificationID !== notificationId))
      setAnnouncements(
        announcements.filter((announcement) => announcement.notificationID !== notificationId)
      )
    }
  }
  const handleRemoveNotification = async (notificationId) => {
    const response = await fetchData(removeNotification(notificationId))
    if (response.success) {
      setAlerts(alerts.filter((alert) => alert.notificationID !== notificationId))
      setAnnouncements(
        announcements.filter((announcement) => announcement.notificationID !== notificationId)
      )
    }
  }

  const handleFetchNotifications = async () => {
    if (!authenticated || loading) return
    setLoading(true)
    console.debug('polling notifications...')
    try {
      const response = await fetchData(getActiveAccountNotifications())
      if (response.success) {
        setAlerts(response.alerts)
        setAnnouncements(response.announcements)
      }
    } finally {
      setTimeout(() => setLoading(false), 50)
    }
  }

  const initPolling = () => {
    handleFetchNotifications()
    // setInterval should be ok here since the polling interval is intended to be
    // much higher than the call latency. If this is not the case we should consider
    // returning a timeout handle with a recursive call
    return setInterval(() => {
      handleFetchNotifications()
    }, NOTIFICATION_POLLING_INTERVAL_MS)
  }

  React.useEffect(() => {
    setHasNotifications(alerts?.length > 0 || announcements?.length > 0)
  }, [alerts, announcements])

  React.useEffect(() => {
    let interval = initPolling()
    const visibilityChangeListener = () => {
      // always clear interval on viz change
      interval && clearInterval(interval)

      // re-initialize when visible
      if (document.hidden) {
        console.debug('pausing notification polling')
      } else {
        console.debug('resuming notification polling')
        interval = initPolling()
      }
    }
    const refreshNotifications = () => {
      handleFetchNotifications()
    }
    document.addEventListener('visibilitychange', visibilityChangeListener)
    document.addEventListener(EVENT_REFRESH_NOTIFICATIONS, refreshNotifications)
    return () => {
      // clear interval and remove event listener to prevent memory leaks
      clearInterval(interval)
      document.removeEventListener('visibilitychange', visibilityChangeListener)
      document.removeEventListener(EVENT_REFRESH_NOTIFICATIONS, refreshNotifications)
    }
  }, [authenticated]) // depend on authenticated state since polling can only happen after authentication

  const contextValue = {
    alerts,
    announcements,
    loading,
    hasNotifications,
    acknowledgeNotification: React.useCallback((id) => handleAcknowledgeNotification(id)),
    removeNotification: React.useCallback((id) => handleRemoveNotification(id)),
  }

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  )
}

export default NotificationContextProvider
