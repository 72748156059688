import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { checkProSearchPath, currentPath, re } from '../../helpers/checkProSearchPath'
import styles from './SearchResultList.module.css'

function Highlight({ text, mark }) {
  if (!text || !mark) return text
  const markStart = text
    .toLowerCase()
    .replace(/[\W]+/g, ' ')
    .indexOf(mark.replace(/[\W]+/g, ' ').toLowerCase())
  if (markStart === -1) return text
  const markEnd = markStart + mark.length
  return (
    <span>
      {text.substring(0, markStart)}
      <span className={styles.resultHighlight}>{text.substring(markStart, markEnd)}</span>
      {text.substring(markEnd)}
    </span>
  )
}
function ResultItem({ searchText, slug, name, type, image, locations, profile }) {
  return (
    <a href={`/${slug}`} className={clsx(styles.resultItem, 'searchResultListItem')}>
      <img
        alt={name}
        src={`https://i.mdel.net/i/db/${image}`}
        className={clsx(styles.resultImage, profile ? styles.avatar : styles.logo)}
      />
      <div className={styles.resultItemContent}>
        <div className={styles.resultName}>
          <Highlight text={name} mark={searchText} />
        </div>
        {type?.length > 0 && <div className={styles.resultType}>{type}</div>}
        {locations?.length > 0 && (
          <div className={styles.resultLocation}>
            {locations.map((location) => (
              <div className="locationCities" key={location}>
                {location}
              </div>
            ))}
          </div>
        )}
      </div>
    </a>
  )
}

export default function SearchResultList({
  agencies,
  clients,
  people,
  links,
  resultCount,
  personTypes,
  searchText,
  onClear,
}) {
  if (resultCount < 0) return <></>
  return (
    <div className={styles.SearchResultList}>
      <div className={styles.container}>
        {personTypes?.length > 0 && (
          <div className={styles.personTypes}>
            <div className={styles.personTypesHeader}>
              <div className={styles.personTypesHeaderTitle}>Models.com ProSearch</div>
              <div>
                {checkProSearchPath(re, currentPath) ? (
                  <Link to={`/search/all`} onClick={onClear}>
                    See all roles
                  </Link>
                ) : (
                  <a href="/search/all">See all roles</a>
                )}
              </div>
            </div>
            <div className={styles.sectionLinks}>
              {personTypes.map &&
                personTypes.map((type) => {
                  return checkProSearchPath(re, currentPath) ? (
                    <Link key={type.slug} to={`/search/${type.slug}`} onClick={onClear}>
                      <Highlight text={type.name} mark={searchText} />
                    </Link>
                  ) : (
                    <a key={type.slug} href={`/search/${type.slug}`} onClick={onClear}>
                      <Highlight text={type.name} mark={searchText} />
                    </a>
                  )
                })}
            </div>
          </div>
        )}
        {people !== 'allPersonTypes' && ( // flag for only showing personTypes
          <div className={styles.resultGrid}>
            <div>
              <div className={styles.sectionTitle}>People</div>
              <div className={styles.sectionLinks}>
                {people?.length > 0 ? (
                  people.map((person) => (
                    <ResultItem
                      key={person.slug}
                      {...person}
                      profile={true}
                      searchText={searchText}
                    />
                  ))
                ) : (
                  <div className={styles.noResults}>No results</div>
                )}
              </div>
            </div>
            <div>
              <div className={styles.sectionTitle}>Agencies</div>
              <div className={styles.sectionLinks}>
                {agencies?.length > 0 ? (
                  agencies.map((agency) => (
                    <ResultItem key={agency.slug} {...agency} searchText={searchText} />
                  ))
                ) : (
                  <div className={styles.noResults}>No Results</div>
                )}
              </div>
              <div className={styles.sectionTitle}>Clients</div>
              <div className={styles.sectionLinks}>
                {clients?.length > 0 ? (
                  clients.map((client) => (
                    <ResultItem key={client.slug} {...client} searchText={searchText} />
                  ))
                ) : (
                  <div className={styles.noResults}>No Results</div>
                )}
              </div>
              <div className={styles.sectionTitle}>Links</div>
              <div className={styles.sectionLinks}>
                {links?.length > 0 ? (
                  links.map((link) => (
                    <ResultItem key={link.slug} {...link} searchText={searchText} />
                  ))
                ) : (
                  <div className={styles.noResults}>No Results</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
