import React, { Suspense } from 'react'
import { createBrowserRouter, Navigate, Route, RouterProvider, Routes } from 'react-router-dom'
import './base.css'
import './App.css'
import MdcAppLoader from './components/MdcAppLoader'
import TopNav from './components/Navigation/TopNav'
import PeopleTabLoader from './components/PeopleTabLoader'
import PersonSettingsLoader from './components/PersonSettingsLoader'
import PlaylistsEmbedStandAloneLoader from './components/PlaylistsEmbedStandAloneLoader'
import { PERSON_TYPES } from './mdc_modules/mdcConsts'
import AuthContextProvider, { AuthContext } from './providers/AuthContextProvider'
import NotificationContextProvider from './providers/NotificationContextProvider'

// code splitting imports lazy loaded components:
const Playlists = React.lazy(() => import('./Playlists' /* webpackChunkName: "Playlists" */))
const ProSearch = React.lazy(() => import('./ProSearch' /* webpackChunkName: "ProSearch"  */))
const PersonActionLauncher = React.lazy(
  () => import('./components/PersonActionLauncher' /* webpackChunkName: "PersonActionLauncher" */)
)
const AgencyRepModalLauncher = React.lazy(
  () =>
    import(
      './components/AgencyRepresentation/AgencyRepModalLauncher' /* webpackChunkName: "AgencyRepModalLauncher" */
    )
)
const RepresentedTalentPage = React.lazy(
  () => import('./pages/RepresentedTalentPage' /* webpackChunkName: "RepresentedTalent"  */)
)
const TalentPageRequestPage = React.lazy(
  () => import('./pages/TalentPageRequestsPage' /* webpackChunkName: "TalentPageRequestPage" */)
)

const AgencyEditPage = React.lazy(
  () => import('./pages/AgencyEditPage' /* webpackChunkName: "AgencyEditPage" */)
)

const AgencyAffiliationPage = React.lazy(
  () => import('./pages/AgencyAffiliationPage' /* webpackChunkName: "AgencyAffiliationPage" */)
)

const AgencyBillingPage = React.lazy(
  () => import('./pages/AgencyBillingPage' /* webpackChunkName: "AgencyBillingPage" */)
)

// maybe not worth splitting tiny:
const IndexAllPersonTypes = React.lazy(
  () => import('./components/IndexAllPersonTypes' /* webpackChunkName: "searchall" */)
)

const RelatedPeopleModule = React.lazy(
  () =>
    import(
      './components/RelatedPeople/RelatedPeopleModule' /* webpackChunkName: "RelatedPeopleModule" */
    )
)

const AccountNotificationPage = React.lazy(
  () => import('./pages/AccountNotificationPage' /* webpackChunkName: "AccountNotificationPage" */)
)

const TalentRankingSuggestionPage = React.lazy(
  () =>
    import(
      './pages/TalentRankingSuggestionPage' /* webpackChunkName: "TalentRankingSuggestionPage" */
    )
)

const GroupSettingsPage = React.lazy(
  () => import('./pages/GroupSettingsPage' /* webpackChunkName: "GroupSettingsPage" */)
)

const MotyNominationPage = React.lazy(
  () => import('./pages/MotyNominationPage' /* webpackChunkName: "MotyNominationPage" */)
)

const AgencyDirectoryPage = React.lazy(
  () => import('./pages/AgencyDirectoryPage' /* webpackChunkName: "AgencyDirectoryPage" */)
)

// possible returns from lucee: Settings.userLevel: proSearch/basicSearch/error/notLoggedIn

function AppRoutes() {
  // load default person types
  const personTypes = PERSON_TYPES
  const { loading, userSettings } = React.useContext(AuthContext)

  return (
    <React.Fragment>
      <TopNav />
      <div id="dialog-root" />
      <Routes>
        {!loading ? (
          <React.Fragment>
            <Route path="/search" element={<Navigate to="/search/model" replace />} />
            <Route
              path="/account/agency-listing/talent/"
              element={
                <Suspense fallback={<MdcAppLoader loaderCentered={true} />}>
                  <RepresentedTalentPage />
                </Suspense>
              }
            />
            <Route
              path="/account/agency-listing/ranking-suggestions/"
              element={
                <Suspense fallback={<MdcAppLoader loaderCentered={true} />}>
                  <TalentRankingSuggestionPage
                    featureEnabled={userSettings?.agencyAssociations?.some(
                      (a) => a.agencyLevelID > 1 && ['ag', 'ma', 'sc'].includes(a.agencyType)
                    )}
                  />
                </Suspense>
              }
            />
            <Route
              path="/account/talent-page-request/"
              element={
                <Suspense fallback={<MdcAppLoader loaderCentered={true} />}>
                  <TalentPageRequestPage
                    isAgencyRep={
                      userSettings &&
                      userSettings.agencyAssociations &&
                      userSettings.agencyAssociations.length
                    }
                  />
                </Suspense>
              }
            />
            <Route
              path="/account/agency-listing/edit/"
              element={
                <Suspense fallback={<></>}>
                  <AgencyAffiliationPage />
                </Suspense>
              }
            />
            <Route
              path="/account/agency-listing/edit/:agencyPostSlug"
              element={
                <Suspense fallback={<></>}>
                  <AgencyEditPage />
                </Suspense>
              }
            />
            <Route
              path="/account/agency-listing/agency-billing/:agencyPostSlug"
              element={
                <Suspense fallback={<></>}>
                  <AgencyBillingPage />
                </Suspense>
              }
            />
            <Route
              path="/account/notifications/account-notifications"
              element={
                <Suspense fallback={<></>}>
                  <AccountNotificationPage />
                </Suspense>
              }
            />
            <Route
              path="/account/settings/groupSettings/"
              element={
                <Suspense fallback={<></>}>
                  <GroupSettingsPage />
                </Suspense>
              }
            />
            <Route
              path="/agencies/:groupType?/:country?/:city?/:gender?"
              element={
                <Suspense fallback={<></>}>
                  <AgencyDirectoryPage />
                </Suspense>
              }
            />
            <Route
              path="/models/:personPostSlug/people/:personType?"
              element={
                <>
                  <PeopleTabLoader userSettings={userSettings} />
                  <Suspense fallback={<></>}>
                    <RelatedPeopleModule />
                    {window.playlistModalData && (
                      <>
                        <PersonActionLauncher
                          userSettings={userSettings}
                          profileInfo={window.playlistModalData}
                        />
                        <AgencyRepModalLauncher
                          personPostSlug={window.playlistModalData.post_slug}
                          profileInfo={window.playlistModalData}
                        />
                      </>
                    )}
                  </Suspense>
                </>
              }
            />
            <Route
              path="/models/:personPostSlug/*"
              element={
                <Suspense fallback={<></>}>
                  <RelatedPeopleModule />
                  {window.playlistModalData && (
                    <>
                      <PersonActionLauncher
                        userSettings={userSettings}
                        profileInfo={window.playlistModalData}
                      />
                      <AgencyRepModalLauncher
                        personPostSlug={window.playlistModalData.post_slug}
                        profileInfo={window.playlistModalData}
                      />
                    </>
                  )}
                </Suspense>
              }
            />
            <Route
              path="/people/:personPostSlug/people/:personType?"
              element={
                <>
                  <PeopleTabLoader userSettings={userSettings} />
                  <Suspense fallback={<></>}>
                    <RelatedPeopleModule />
                    {window.playlistModalData && (
                      <>
                        <PersonActionLauncher
                          userSettings={userSettings}
                          profileInfo={window.playlistModalData}
                        />
                        <AgencyRepModalLauncher
                          personPostSlug={window.playlistModalData.post_slug}
                          profileInfo={window.playlistModalData}
                        />
                      </>
                    )}
                  </Suspense>
                </>
              }
            />
            <Route
              path="/people/:personPostSlug/*"
              element={
                <Suspense fallback={<></>}>
                  <RelatedPeopleModule />
                  {window.playlistModalData && (
                    <>
                      <PersonActionLauncher
                        userSettings={userSettings}
                        profileInfo={window.playlistModalData}
                      />
                      <AgencyRepModalLauncher
                        personPostSlug={window.playlistModalData.post_slug}
                        profileInfo={window.playlistModalData}
                      />
                    </>
                  )}
                </Suspense>
              }
            />
            <Route path="/oftheminute/" element={<PlaylistsEmbedStandAloneLoader />} />
            <Route
              path="/account/settings/person/:personPostSlug"
              element={<PersonSettingsLoader userSettings={userSettings} />}
            />
            <Route
              path="/search/all"
              element={
                <Suspense fallback={<MdcAppLoader />}>
                  <IndexAllPersonTypes
                    rootPath="/search"
                    personTypes={userSettings.personTypes || personTypes}
                    searchRootPath="/search"
                    listsRootPath="/lists"
                  />
                </Suspense>
              }
            />
            <Route
              path="/lists/*"
              element={
                <Suspense fallback={<MdcAppLoader />}>
                  <Playlists
                    rootPath="/lists"
                    searchRootPath="/search"
                    listsRootPath="/lists"
                    userSettings={userSettings}
                    personTypes={userSettings.personTypes || personTypes}
                  />
                </Suspense>
              }
            />
            <Route
              strict
              path="/search/:linkPersonTypePostSlug"
              element={
                <Suspense fallback={<MdcAppLoader loaderCentered={true} />}>
                  <ProSearch
                    rootPath="/search"
                    searchRootPath="/search"
                    listsRootPath="/lists"
                    userSettings={userSettings}
                    personTypes={userSettings.personTypes || personTypes}
                  />
                </Suspense>
              }
            />
            <Route
              path="/account/moty/nominate"
              element={
                <Suspense fallback={<></>}>
                  <MotyNominationPage />
                </Suspense>
              }
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route
              path="/lists/"
              element={MdcAppLoader}
              personTypes={userSettings.personTypes || personTypes}
            />
            <Route
              path="/search/"
              element={MdcAppLoader}
              personTypes={userSettings.personTypes || personTypes}
            />
          </React.Fragment>
        )}
      </Routes>
    </React.Fragment>
  )
}

function Root() {
  React.useEffect(() => {
    // we used to use localStorage to save filter search results.
    // cleanup any leftover clients
    localStorage.removeItem('mdcSearch')
  }, [])
  return (
    <AuthContextProvider>
      <NotificationContextProvider>
        <AppRoutes />
      </NotificationContextProvider>
    </AuthContextProvider>
  )
}

const router = createBrowserRouter([{ path: '*', Component: Root }])

function App() {
  return <RouterProvider router={router} />
}

export default App
