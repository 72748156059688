export default function RequestConfirmation({ message, onConfirm }) {
  return (
    <div className="sb-pop-up-wrapper__content-wrapper">
      <div className="db_reset_password_pop_up__wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <g clipPath="url(#clip0_5623_5517)">
            <path
              d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
              fill="#F7F7F7"
            ></path>
            <path
              d="M12.5 20L17.5 25L27.5 15"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_5623_5517">
              <rect width="40" height="40" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>

        <p className="db_reset_password_pop_up__hint">{message}</p>
        <button type="button" className="sb-button sb-button--primary" onClick={onConfirm}>
          Continue
        </button>
      </div>
    </div>
  )
}
