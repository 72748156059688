import clsx from 'clsx'
import { cloneElement, useEffect, useRef, useState } from 'react'
import styles from './PopoverMenu.module.css'

export default function PopoverMenu({ toggle, children }) {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = (event) => {
    event.preventDefault()
    setMenuOpen((prev) => !prev)
  }

  const popoverRef = useRef(null)
  useEffect(() => {
    const cb = (event) => {
      if (!popoverRef.current.contains(event.target)) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('click', cb)
    return () => document.removeEventListener('click', cb)
  }, [])

  return (
    <div className={styles.listMenu} ref={popoverRef}>
      {cloneElement(toggle, { onClick: toggleMenu })}
      <div className={clsx(styles.listMenuOverlay, { [styles.hide]: !menuOpen })}>{children}</div>
    </div>
  )
}

export function Separator() {
  return (
    <div
      style={{
        border: 'solid #ddd',
        borderWidth: '1px 0 0',
        height: '0px',
        margin: '15px 0 16px 0',
      }}
    ></div>
  )
}
