import { Link } from 'react-router-dom'
import PopoverMenu, { Separator } from './PopoverMenu'
import styles from './PopoverMenu.module.css'

export default function PeopleNavMenu() {
  return (
    <PopoverMenu toggle={<a href="/search/all/">People</a>}>
      <>
        <Link className={styles.listMenuItem} reloadDocument to="/search/model">
          Models
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/photographer">
          Photographers
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/fashion-editor-stylist">
          Fashion Editors/Stylists
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/makeup-artist">
          Makeup Artists
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/hair-stylist">
          Hair Stylists
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/casting-director">
          Casting Directors
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/set-designer">
          Set Designers
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/art-director">
          Art Directors
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/creative-director">
          Creatives Directors
        </Link>

        <Link className={styles.listMenuItem} reloadDocument to="/search/producer">
          Producers
        </Link>

        <Separator />

        <Link className={styles.listMenuItem} reloadDocument to="/search/all">
          View all Roles
        </Link>
      </>
    </PopoverMenu>
  )
}
