import clsx from 'clsx'
import { useState } from 'react'
import styles from './LoginButton.module.css'

export default function LoginButton({ children, label = 'Log In', open, onToggle }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleToggle = () => {
    onToggle()
  }
  return (
    <div>
      <button
        className={clsx(styles.loginBtn, { [styles.loginBtnOpen]: open })}
        onClick={handleToggle}
      >
        {label}
        <span className={styles.loginBtnIcon}></span>
      </button>
      {children}
    </div>
  )
}
