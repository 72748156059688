import React, { Suspense, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { useParams } from 'react-router-dom'
import MdcAppLoader from './MdcAppLoader'

const PersonSettings = React.lazy(
  () => import('./PersonSettings' /* webpackChunkName: "PersonSettings" */)
)
const LoginModal = React.lazy(() => import('./LoginModal' /* webpackChunkName: "LoginModal" */))

function PersonSettingsLoader({ userSettings, containerId = 'personsettings-root' }) {
  const { personPostSlug } = useParams()
  useEffect(() => {
    let root
    const container = document.getElementById(containerId)
    if (container) {
      root = createRoot(container)
      if (userSettings?.authenticated) {
        root.render(
          <Suspense fallback={<MdcAppLoader />}>
            <PersonSettings personPostSlug={personPostSlug} />
          </Suspense>
        )
      } else {
        root.render(
          <Suspense fallback={<MdcAppLoader />}>
            <LoginModal />
          </Suspense>
        )
      }
    }
    return () => {
      setTimeout(() => root?.unmount())
    }
  }, [userSettings?.authenticated, personPostSlug, containerId])
  return <></>
}

export default PersonSettingsLoader
